/* unplugin-vue-components disabled */var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',_vm._b({attrs:{"table-id":"brand_sellers","id":_vm.id,"columns":_vm.columns,"loader":_vm.tableLoader,"loaderConfig":_vm.reportSettings,"item-id":"s_id"},on:{"selectAction":function ($event) { return $event.addToList({
                entity: 'seller',
                mp: this$1.reportSettings.mp,
            }); },"selectActionM":function ($event) { return $event.addToListM({
                entity: 'seller',
                mp: this$1.reportSettings.mp,
            }); }}},'data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }