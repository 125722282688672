//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    path: {
      type: Array,
      default: () => {
        return [];
      }
    },
    categories: {
      type: Array,
      default: () => {
        return [];
      }
    },
    routeParams: {
      type: Object,
      default: () => {
        return {};
      }
    },
    routeQuery: {
      type: Object,
      default: () => {
        return {};
      }
    },
    light: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: null
    },
    block: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getRouteParams(item) {
      return {
        mp: this.$route.params.mp,
        ...this.routeParams,
        c_id: item.c_id
      };
    }
  },
  computed: {
    resolvedPath() {
      return this.path.map(c_id => this.categories.find(item => c_id === item.c_id));
    }
  }
};